import React from 'react'
import "./abouthero.css";
import lady from "../../assets/abouthero/bussiness-people-working-team-office 1.png";
import ladyBg from "../../assets/landingPage/blueCircle.svg";

const Abouthero = (props) => {
    return (
        <div className="recruitment_abouthero">
          <div className="recruitment_abouthero_content">
            <div className="recruitment_abouthero_content_heading-div">
              <p className="recruitment_abouthero_heading">About Us</p>
              
            </div>
            <p className="recruitment_abouthero_desc">
              At our recruitment agency, we are passionate about connecting talented individuals with their perfect career opportunities. Trust us to find yours.
            </p>
            <div className="recruitment_abouthero_search_main">
              <button>Explore Jobs</button>
            </div>
          </div>
          <div className="recruitment_abouthero_img-div">
            <img className="recruitment_abouthero_img" src={lady} alt="" />
            <img className="recruitment_abouthero_imgBg" src={ladyBg} alt="" />
          </div>
        </div>
      );
    };

export default Abouthero
