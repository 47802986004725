import React from "react";
import "./hero.css";
import lady from "../../assets/landingPage/businesswoman.svg";
import ladyBg from "../../assets/landingPage/blueCircle.svg";

import { CiSearch, CiLocationOn } from "react-icons/ci";

const Hero = (props) => {
  return (
    <div className="recruitment_hero">
      <div className="recruitment_hero_content">
        <div className="recruitment_hero_content_heading-div">
          <p className="recruitment_hero_heading">Find your job</p>
          <p className="recruitment_hero_heading">better and faster</p>
        </div>
        <p className="recruitment_hero_desc">
          Discover the ultimate job search experience - find your dream job faster and unlock new career opportunities with our powerful tools and resources.
        </p>
        <div className="recruitment_hero_search_main">
          <div className="recruitment_hero_search_div">
            <CiSearch />
            <input placeholder="Job title" type="text" />
            <CiLocationOn />
            <input placeholder="Location" type="text" />
          </div>
          <button>Search</button>
        </div>
      </div>
      <div className="recruitment_hero_img-div">
        <img className="recruitment_hero_img" src={lady} alt="" />
        <img className="recruitment_hero_imgBg" src={ladyBg} alt="" />
      </div>
    </div>
  );
};

export default Hero;
