import React from "react";
import "./Services.css";

import ServicesData from "../../data/ServicesData.jsx";

import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <div className="recruitment_services">
      <p className="recruitment_services_title">Services we offer</p>
      <p className="recruitment_services_desc">
        Discover your dream team. Our recruitment agency specializes in finding
        top talent for your business. Streamlined, efficient, and effective
        hiring solutions.
      </p>
      <div className="recruitment_services_cards-div">
        {ServicesData.map((item) => (
          <div key={item.id} className="recruitment_services_card">
            <img
              className="recruitment_services_card_img"
              src={item.img}
              alt=""
            />
            <p className="recruitment_services_card_title">{item.title}</p>
            <p className="recruitment_services_card_desc">{item.desc}</p>
            <Link
              to={`/service/${item.id}/${item.title
                .replace(/ /g, "-")
                .toLowerCase()}`}
            >
              <button className="recruitment_services_card_btn">
                Learn More <HiOutlineArrowNarrowRight />
              </button>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
