import React from 'react'
import Jobhero from '../../components/Jobhero/Jobhero'
import Testimonials from '../../components/Testimonials/Testimonials'
import Jobcard from '../../components/JobCards/Jobcard'

const Job = () => {
  return (
    <div>
        <Jobhero />
        <Jobcard />
        <Testimonials />
    </div>
  )
}

export default Job