import React from "react";
import "./counter.css";

import { FaUser, FaUsers, FaBuilding } from "react-icons/fa";

const Counter = () => {
  return (
    <div className="recruitment_counter-main">
      <div className="recruitment_counter-section">
        <FaUsers className="recruitment_counter_icon " />
        <h2 className="recruitment_counter-heading">5000+</h2>
        <p className="recruitment_counter-paragraph">Careers made till date</p>
      </div>
      <div className="recruitment_counter-section">
        <FaUser className="recruitment_counter_icon" />
        <h2 className="recruitment_counter-heading">50+</h2>
        <p className="recruitment_counter-paragraph">Consultant</p>
      </div>
      <div className="recruitment_counter-section">
        <FaBuilding className="recruitment_counter_icon" />
        <h2 className="recruitment_counter-heading">50+</h2>
        <p className="recruitment_counter-paragraph">Industries covered</p>
      </div>
    </div>
  );
};

export default Counter;
