import React from 'react'
import "./servicehero.css";
import lady from "../../assets/servicehero/bussiness-people-working-team-office 1.png";
import ladyBg from "../../assets/landingPage/blueCircle.svg";

const Serviceservicehero = (props) => {
    return (
        <div className="recruitment_servicehero">
          <div className="recruitment_servicehero_content">
            <div className="recruitment_servicehero_content_heading-div">
              <p className="recruitment_servicehero_heading">Find your Dream</p>
              <p className="recruitment_servicehero_heading">job in one place</p>
            </div>
            <p className="recruitment_servicehero_desc">
              Discover your dream job with our comprehensive recruitment services. We provide a one-stop platform to connect talented individuals with top-notch opportunities, making your job search a breeze. Trust us to find your perfect fit.
            </p>
            <div className="recruitment_servicehero_search_main">
              <button>Explore Jobs</button>
            </div>
          </div>
          <div className="recruitment_servicehero_img-div">
            <img className="recruitment_servicehero_img" src={lady} alt="" />
            <img className="recruitment_servicehero_imgBg" src={ladyBg} alt="" />
          </div>
        </div>
      );
    };

export default Serviceservicehero
