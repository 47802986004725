import React from "react";
import "./footer.css";
// import { BsInstagram, BsFacebook } from "react-icons/bs";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo v2.png";

import location from "../../assets/contactcard/Vector.png";
import whitecall from "../../assets/contactcard/bxs_phone-call.png";
import mail from "../../assets/contactcard/ic_sharp-email.png";

import fbLogo from "../../assets/footer/fbLogo.png";
import instaLogo from "../../assets/footer/instaLogo.png";
import twitLogo from "../../assets/footer/twitLogo.png";
import linkLogo from "../../assets/footer/linkLogo.png";

const Footer = () => {
  return (
    <div className="linksoft_footer">
      <Link
        to="/"
        onClick={() => window.scrollTo(0, 0)}
        className="linksoft_footer_logo_mobile"
      >
        <img src={Logo} alt="" />
      </Link>
      <div className="linksoft_footer-newsletter_container">
        <p className="linksoft_footer-desc">
          Stay in the loop with our latest job openings, career tips, and
          industry insights. Subscribe to our newsletter and be the first to
          know about exciting opportunities. Join our community of talented
          professionals and let us help you navigate your career path. Sign up
          now!
        </p>
        <div className="linksoft_footer-newsletter_input_div">
          <input
            className="linksoft_footer-newsletter_input"
            type="email"
            placeholder="Your email"
          />
          <button className="linksoft_footer-newsletter_btn">Subscribe</button>
        </div>
      </div>
      <div className="linksoft_footer-links_main-div">
        <div className="linksoft_footer-links_links-main-div">
          {/* <div className="linksoft_footer-links_div"> */}
          <Link
            className="linksoft_footer_logo-link"
            to="/"
            onClick={() => window.scrollTo(0, 0)}
          >
            <img className="linksoft_footer_logo" src={Logo} alt="" />
          </Link>
          {/* </div> */}
          <div className="linksoft_footer-links_small-div">
            <p className="linksoft_footer-title">Quick Links</p>
            <div className="linksoft_footer-links">
              <li className="linksoft_footer-links_li">
                <Link to="/">Home</Link>
              </li>
              <li className="linksoft_footer-links_li">
                <Link to="/service">Service</Link>
              </li>
              <li className="linksoft_footer-links_li">
                <Link to="/jobs">Job</Link>
              </li>
              <li className="linksoft_footer-links_li">
                <Link to="/why-us">Why Us</Link>
              </li>
              <li className="linksoft_footer-links_li">
                <Link to="/contact-us">Contact Us</Link>
              </li>
            </div>
          </div>
          <div className="linksoft_footer-links_div">
            <p className="linksoft_footer-title">Contact Info</p>
            <div className="linksoft_footer_content">
              <div className="linksoft_footer_div">
                <img src={whitecall} alt="" />
                <p className="linksoft_footer_details">+91 81422 21022</p>
              </div>
              <div className="linksoft_footer_div">
                <img src={mail} alt="" />
                <p className="linksoft_footer_details">
                  info@linksoftconsulting.com
                </p>
              </div>
              <div className="linksoft_footer_div">
                <img src={location} alt="" />
                <p className="linksoft_footer_details">
                  No.27, 1A, 2nd Cross Rd, Vijaya Bank Colony, Dodda Banaswadi,
                  Bengaluru, Karnataka 560043
                </p>
              </div>
              <div className="linksoft_footer_div">
                <img src={location} alt="" />
                <p className="linksoft_footer_details">
                  Guntur, Andhra Pradesh
                </p>
              </div>
              {/*
              <div className="linksoft_footer_div">
                <img src={location} alt="" />
                <p className="linksoft_footer_details">
                  132 Dartmouth Street Boston, Massachusetts 02156 United States
                </p>
              </div>*/}
            </div>
          </div>
          <div className="linksoft_footer-links_small-div">
            <p className="linksoft_footer-title">Social Links</p>
            <div className="linksoft_footer_social-main">
              <a
                className="linksoft_footer_social-div"
                href="http://facebook.com/linksoftconsulting"
                target="_blank"
                rel="noreferrer"
              >
                <img src={fbLogo} alt="" />
                <div className="linksoft_footer_social-link-div">
                  <p className="linksoft_footer_social-link-title">
                    Follow us on facebook
                  </p>
                  <p className="linksoft_footer_social-link-title">fb.com/linksoftconulting</p>
                </div>
              </a>
              <a
                className="linksoft_footer_social-div"
                href="https://www.instagram.com/linksoft_consulting/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={instaLogo} alt="" />
                <div className="linksoft_footer_social-link-div">
                  <p className="linksoft_footer_social-link-title">
                    Follow us on Instagram
                  </p>
                  <p className="linksoft_footer_social-link-title">instagram.com/linksoft_consulting</p>
                </div>
              </a>
              <a
                className="linksoft_footer_social-div"
                href="https://twitter.com/LinksoftConsult"
                target="_blank"
                rel="noreferrer"
              >
                <img src={twitLogo} alt="" />
                <div className="linksoft_footer_social-link-div">
                  <p className="linksoft_footer_social-link-title">
                    Follow us on Twitter
                  </p>
                  <p className="linksoft_footer_social-link-title">twitter.com/LinksoftConsult</p>
                </div>
              </a>
              <a
                className="linksoft_footer_social-div"
                href="https://www.linkedin.com/in/linksoft-consulting-services"
                target="_blank"
                rel="noreferrer"
              >
                <img src={linkLogo} alt="" />
                <div className="linksoft_footer_social-link-div">
                  <p className="linksoft_footer_social-link-title">
                    Follow us on LinkedIn
                  </p>
                  <p className="linksoft_footer_social-link-title">linkedin.com/in/linksoft-consulting-services</p>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="linksoft_footer_copyright-div">
          © 2023 Your Smart Step Classes. All rights reserved.
        </div>
      </div>
    </div>
  );
};

export default Footer;
