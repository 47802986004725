import React from "react";
import "./jobhero.css";
import img from "../../assets/jobhero/colleagues-man-woman-planning-looking-laptop 1.png";
import imgBg from "../../assets/landingPage/blueCircle.svg";

const Jobhero = (props) => {
  return (
    <div className="recruitment_jobhero">
      <div className="recruitment_jobhero_content">
        <div className="recruitment_jobhero_content_heading-div">
          <p className="recruitment_jobhero_heading">Search for your</p>
          <p className="recruitment_jobhero_heading">Dream job</p>
        </div>
        <p className="recruitment_jobhero_desc">
          Explore endless possibilities and find your dream job with our
          recruitment agency. Browse through a wide range of exciting
          opportunities and take the first step towards a fulfilling career.
          Your perfect job awaits!
        </p>
        <div className="recruitment_jobhero_search_main">
          <button>Explore Jobs</button>
        </div>
      </div>
      <div className="recruitment_jobhero_img-div">
        <img className="recruitment_jobhero_img" src={img} alt="" />
        <img className="recruitment_jobhero_imgBg" src={imgBg} alt="" />
      </div>
    </div>
  );
};

export default Jobhero;
