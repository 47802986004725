import React from "react";
import Hero from "../../components/Hero/Hero";
import Counter from "../../components/Counter/Counter";
import About from "../../components/About/About";
import Services from "../../components/Services/Services";
import RightJob from "../../components/RightJob/RightJob";
import Contact from "../../components/Contact/Contact";
import Testimonials from "../../components/Testimonials/Testimonials";

const Landing = () => {
  return (
    <div>
      <Hero />
      <Counter />
      <About />
      <Services />
      <RightJob />
      <Contact />
      <Testimonials />
    </div>
  );
};

export default Landing;
