import it from "../assets/services/it.svg";
import hiredeploy from "../assets/services/hiredeploy.svg";
import international from "../assets/services/international.svg";
import laptop from "../assets/services/laptop.svg";
import leader from "../assets/services/leader.svg";
import msp from "../assets/services/msp.svg";
import outsourching from "../assets/services/outsourching.svg";
import permanent from "../assets/services/permanent.svg";
import staffing from "../assets/services/staffing.svg";
import talentCarrer from "../assets/services/talentCarrer.svg";

const ServicesData = [
  {
    id: 1,
    img: it,
    title: "IT Recruitment",
    desc: "Connecting top tech talent with leading organizations.",
    content: (
      <div className="linksoft_single-service">
        <p>
          We’re a team of IT recruiters who have been helping companies in your
          industry find the talent they need.
        </p>
        <p>
          We’ve worked with many different types of companies, including
          start-ups and large firms, so we know what it’s like to work with HR
          departments that are looking to fill open roles. We work closely with
          clients to understand their needs, and then research and source
          candidates according to those needs.
        </p>
        <p>
          We’re not just about finding candidates for your company—we also help
          you communicate the message that your company is looking for in a
          candidate. Our ability to tailor our approach makes us unique, and
          allows us to connect with candidates at all levels of experience.
        </p>
      </div>
    ),
  },
  {
    id: 2,
    img: leader,
    title: "Leadership Hiring",
    desc: "Identifying exceptional leaders for your organization's success.",
    content: (
      <div className="linksoft_single-service">
        <p>
          Leadership hiring is our specialty. We’ve been doing it for years, and
          we’re just better than anyone else at it.
        </p>
        <br />
        <p>Here’s why:</p>
        <p>
          1. We know how to find great candidates. We have a network of
          recruiters across the country who are experts at finding the right
          people at the right time, and they know that if they can’t find
          someone you’ll be happy with, then we won’t either. They work with us
          to match the best candidates with the right jobs—and then we do what
          we do best: interview them, check references, make sure they’re a good
          cultural fit for your company, and check their background again to
          make sure there aren’t any red flags.
        </p>
        <p>
          2. We get you in front of great people fast! Our recruiters are so
          good at finding top-notch talent that we don’t need to spend time
          vetting everyone—we can hand them off directly to our hiring managers
          so they can start interviewing them right away! And because our hiring
          managers are experts in recruiting as well as leadership hiring, they
          know exactly what questions to ask during each step of the process so
          that you get a high-quality candidate fast!
        </p>
        <p>3. We know how to make your job easier</p>
      </div>
    ),
  },
  {
    id: 3,
    img: staffing,
    title: "Professional Staffing",
    desc: "Matching skilled professionals with the right opportunities.",
    content: (
      <div className="linksoft_single-service">
        <p>
          We offer a wide range of services to help you find the right people
          for your company. Our experienced recruiters will work with you to
          determine where you are, what you need and how to get there. We know
          that hiring is an important step in growing your business and we want
          to make sure our clients are getting the best possible service.
        </p>
        <p>
          Our recruitment process starts with finding the right candidate for
          your role, so we can ensure that they’re the right fit for your
          organization. We then work with them to schedule an interview and
          conduct background checks on candidates. From there, we’ll review all
          applications and select the best possible match for each position.
        </p>
        <p>
          Once we have a candidate selected, we’ll coordinate their hiring
          process through our team of specialists in HR, Finance and Legal
          services so that everything can run smoothly from start to finish.
        </p>
        <p>Benefits of Choosing us for hr services:</p>
        <p>
          1. We have a team of qualified and experienced HR professionals who
          are committed to providing excellent HR services.
        </p>
        <p>
          2. We have a strong network of organizations from all across the
          country which can help you with your HR needs.
        </p>
        <p>
          3. We offer services at affordable prices without compromising on
          quality and standards.
        </p>
      </div>
    ),
  },
  {
    id: 4,
    img: permanent,
    title: "Permanent Recruitment",
    desc: "Finding the perfect fit for long-term positions.",
    content: (
      <div className="linksoft_single-service">
        <p>
          Efthymos offers permanent recruitement. We are a small and growing
          team of professionals who are passionate about what we do and eager to
          share it with the world. We strive to work in a collaborative
          environment, where everyone has an equal voice and everyone is treated
          with respect.
        </p>
        <p>
          We believe that everyone deserves to be treated with respect,
          regardless of gender, race, religion or sexual orientation. We also
          believe that people should not have to worry about their job security
          because they can’t afford the cost of living here (which is constantly
          shifting).
        </p>
        <p>
          We want everyone to be able to live their lives without fear of being
          laid off at any moment. That’s why we offer full-time employment with
          benefits and a 401k program—as well as flexible hours so you can take
          care of your family or pursue other interests while still working at
          Efthymos.
        </p>
        <p>We offer a number of benefits to our permanent hires, including:</p>
        <p>– An attractive salary and benefits package</p>
        <p>
          – A comprehensive staff development program that includes training in
          job-related skills (like how to work with clients) and business
          management practices (like how to manage employees)
        </p>
        <p>
          – A comprehensive benefits package that includes healthcare, vacation
          time, and sick days.
        </p>
      </div>
    ),
  },
  {
    id: 5,
    img: outsourching,
    title: "Outsourcing",
    desc: "Streamlining your workforce with efficient outsourced solutions.",
    content: (
      <div className="linksoft_single-service">
        <p>
          We offer a full range of outsourcing services for businesses of all
          sizes. With us, you’ll be able to find the right person for any
          job—whether it’s a few hours’ worth of work each week, or a more
          comprehensive project that will take several months.
        </p>
        <p>
          We’ve worked with many different types of businesses over the years
          and have found that they all have one thing in common: they need
          reliable people who can work hard and get things done. We believe that
          you deserve someone who will make sure your business gets off on the
          right foot, and we’re here to help you find just that person.
        </p>
        <p>
          When you choose us as your outsourcer, we’ll take care of everything
          from interviewing candidates until they’re hired—and then we’ll make
          sure they’re trained in all aspects of their role before sending them
          back into the field so that they can start working immediately.
        </p>
        <p>
          Merchandising: Shelf Space Management, Inventory Management, Planogram
          Maintenance, Product Displays, Point Of Purchase and Promotional
          Material Installations, Fixture Installation, and Tracking & Shelf
          Resets and Remodels.
        </p>
        <p>
          Sales Force: Modern and Traditional recruitment, Trade Salesforce
          Outsourcing, Brand/ Beauty Consultants, Feet on Street & Store
          Personnel.
        </p>
        <p>
          Audits: Price, Facings, Shelf Space Data Collection, Planogram and
          Fixture Compliance & Mystery Shopping and Customer Survey’s.
        </p>
        <p>
          Promotions: Product Demonstrations, Sample Give-Away’s & Tasting
          Events.
        </p>
      </div>
    ),
  },
  {
    id: 6,
    img: laptop,
    title: "RPO Lite",
    desc: "Simplifying your recruitment process with our comprehensive RPO solutions.",
    content: (
      <div className="linksoft_single-service">
        <p>
          RPO Lite is a service that manages the entire, or parts of the
          recruitment processes for an organization. We can take charge of
          entire recruitment components or just parts of your value chain. In
          return, you can expect best-in-class delivery with continuous
          optimization and solutions that work. RPO Lite offering includes
          sourcing, screening, assessment administration, interview
          coordination, selection, pre-employment checks & verification, offer
          administration, on-boarding, new hire care, and exit interviews.
        </p>
        <p>
          In this model, efthymos offers its own company staff, technology,
          methodologies, and reports for an effective talent acquisition
          process. You can take advantage of our end-to-end process knowledge.
          We will engage in finding and acquiring skilled manpower for the
          client’s needs and meet the talent requirement as per the agreed SLA.
        </p>
      </div>
    ),
  },
  {
    id: 7,
    img: international,
    title: "International Engagement",
    desc: "Expanding your talent pool globally for international success.",
    content: (
      <div className="linksoft_single-service">
        <p>
          We believe that we are best at international engagement
          services/consulting because we have a diverse team of experts with a
          wide range of experience and knowledge. Our team members come from all
          over the world, with backgrounds in different areas of business and
          life.
        </p>
        <p>
          We also have a history of success in international engagement
          services/consulting that dates back many years. Our clients can count
          on us to deliver the quality service they expect and deserve, no
          matter where they are located.
        </p>
        <p>Offshore recruitment:</p>
        <p>What we offer in offshore recruitement services?</p>
        <p>
          We have the best team of recruiters and consultants who can help you
          to find the right candidate for your job. We help you to assess the
          skills of candidates, and if you want to hire someone in remote, then
          we will find the most suitable candidate for you. We provide our
          clients with all the information they need to make informed decisions
          about their hiring process. Our recruitment process is designed to be
          as simple as possible while providing valuable insights into your
          organization’s needs.
        </p>
        <p>Offshore delivery :</p>
        <p>
          We offer a full range of delivery services to meet your needs. We have
          developed a state-of-the-art facility with a team of highly skilled
          specialists who will make sure that your product reaches you on time.
          Our location in Dubai means that we are able to provide you with the
          fastest and most reliable logistics services available in the market
          today. We have been serving customers around the world since 2011, so
          we know what it takes to get your order right every time.
        </p>
        <p>Global Expatriate Mobilization:</p>
        <p>
          Global Expatriate Mobilization is a full-service international
          relocation and relocation management firm. We have been helping
          members of the global expatriate community since 2003 with their
          relocation needs, and we are proud to serve our clients’ growing
          communities in more than 20 countries around the world.
        </p>
        <p>We offer a wide range of services, including:</p>
        <p>
          – International relocations—from home to home or workplace to
          workplace
        </p>
        <p>
          – Newly created jobs for your expatriates in their new homes or
          workplaces
        </p>
        <p>
          – Training programs for your new employees that will equip them with
          the skills they need to succeed in their new roles
        </p>
        <p>
          – Immigration assistance—we provide guidance on how to make your
          company’s relocation process as smooth and easy as possible so that
          you can focus on what matters most: business operations
        </p>
        <p>Global Shared Service Management Services:</p>
        <p>
          We also offer a full range of Shared Service Management services, from
          project management to operations.
        </p>
        <p>Global Shared Service Management Services</p>
        <p>
          As your shared service provider, we will work with you to identify
          your needs and build a solution that best suits your business. We will
          provide end-to-end services including:
        </p>
        <p>– Project management</p>
        <p>– Operational support</p>
        <p>– Human resource management</p>
        <p>– Information technology support</p>
      </div>
    ),
  },
  {
    id: 8,
    img: hiredeploy,
    title: "Hire Train Deploy",
    desc: "Providing end-to-end solutions for hiring, training, and deployment.",
    content: (
      <div className="linksoft_single-service">
        <p>
          Efthymos has been in the hire-train-deploy business for decades. We’re
          not just one of the longest standing companies in this field, but also
          one of the most successful.
        </p>
        <p>
          We’ve got a ton of experience working with clients who need help with
          their hiring and deployment process. But that’s not all we do. We have
          expertise in a wide range of areas that are integral to the success of
          any project, from coding to security to testing to design and more!
        </p>
        <p>
          We can work with you on any size project—even those that are so big
          they require dozens or even hundreds of people working together at
          once. And if you need help with strategy or planning, we’ve got that
          covered too—we can help you translate your vision into reality by
          drawing up detailed timelines and strategies for how each phase will
          play out, as well as helping you identify and prioritize key
          stakeholders so you can make sure everyone is aligned on what needs to
          happen next.
        </p>
      </div>
    ),
  },
  {
    id: 9,
    img: talentCarrer,
    title: "Talent Development & Career Transition",
    desc: "Empowering professionals with growth opportunities.",
    content: (
      <div className="linksoft_single-service">
        <p>
          The world of work is changing. The skills that were once valued are no
          longer as valuable as they once were, and people who have them are now
          being asked to do things they might not have been asked to do before.
        </p>
        <p>
          In order to make sure your talent is ready for the new world, it’s
          important to do some talent development. This can take many forms,
          from taking a course in how to use Photoshop or LinkedIn or even just
          hanging out with other people who have experience doing the kinds of
          things you want to learn about.
        </p>
        <p>
          The most important thing is that you’re learning new skills and
          expanding your horizons, so that when the time comes for you to make a
          career change or start a business, you’ll be able to adapt quickly and
          easily.
        </p>
      </div>
    ),
  },
  {
    id: 10,
    img: msp,
    title: "MSP & RPO",
    desc: "Managing your contingent workforce with our strategic MSP-RPO solutions.",
    content: (
      <div className="linksoft_single-service">
        <p>
          At Efthymos, we thrive on providing MSP and RPO services to our
          customers. We understand that while technology may be constantly
          evolving, your business can’t afford to change. That’s why we provide
          the expertise you need to manage your MSP and RPO programs
          effectively, so that you can focus on what matters most: the customer
          experience.
        </p>
        <p>
          Our team is made up of experts in MSP and RPO services, with years of
          experience working with companies like yours. We have a passion for
          helping businesses succeed by helping them keep their head above water
          in an ever-changing world. But we don’t just want to help you keep
          afloat; we want to help you swim!
        </p>
        <p>
          We believe that every business should have access to the resources
          they need to succeed in this rapidly changing marketplace—and we’re
          here to make sure that happens for every one of our clients.
        </p>
      </div>
    ),
  },
];

export default ServicesData;
