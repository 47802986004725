import React from "react";
import "./RightJob.css";
import { MdCheckBox } from "react-icons/md";

import lady from "../../assets/rightJob/lady.svg";
import ladyBg from "../../assets/rightJob/circleBg.svg";

const RightJob = () => {
  return (
    <div className="recruitment_right-job">
      <div className="recruitment_right-job_content">
        <p className="recruitment_right-job_title">
          Get the job that’s right for you
        </p>
        <p className="recruitment_right-job_desc">
          Looking for the perfect job? Look no further! 
          Our recruitment agency is here to help you find the ideal opportunity that matches your skills and aspirations. 
          With our expertise and network, we connect you with the right employers and provide personalized guidance throughout the hiring process. 
          Take the first step towards your dream career today!
        </p>
        <div className="recruitment_right-job_desc_div">
          <p className="recruitment_right-job_desc">
            <MdCheckBox className="recruitment_right-job_checkbox" />
            Tailored Job Opportunities
            
          </p>
          <p className="recruitment_right-job_desc">
            <MdCheckBox className="recruitment_right-job_checkbox" />
            Expert Recruitment Team
            
          </p>
          <p className="recruitment_right-job_desc">
            <MdCheckBox className="recruitment_right-job_checkbox" />
            Personalized Job Search Assistance
            
          </p>
          <p className="recruitment_right-job_desc">
            <MdCheckBox className="recruitment_right-job_checkbox" />
            Industry Insights
          </p>
          <p className="recruitment_right-job_desc">
            <MdCheckBox className="recruitment_right-job_checkbox" />
            Resume and Interview Preparation
          </p>
          <p className="recruitment_right-job_desc">
            <MdCheckBox className="recruitment_right-job_checkbox" />
            Network Expansion
          </p>
          <p className="recruitment_right-job_desc">
            <MdCheckBox className="recruitment_right-job_checkbox" />
            Job Market Knowledge
          </p>
          <p className="recruitment_right-job_desc">
            <MdCheckBox className="recruitment_right-job_checkbox" />
            Confidentiality and Privacy
          </p>
          <p className="recruitment_right-job_desc">
            <MdCheckBox className="recruitment_right-job_checkbox" />
            Career Guidance
          </p>
          <p className="recruitment_right-job_desc">
            <MdCheckBox className="recruitment_right-job_checkbox" />
            Long-Term Support
          </p>
        </div>
      </div>
      <div className="recruitment_right-job_img-div">
        <img className="recruitment_right-job_img" src={lady} alt="" />
        <img className="recruitment_right-job_imgBg" src={ladyBg} alt="" />
      </div>
    </div>
  );
};

export default RightJob;
