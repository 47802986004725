import React from "react";
import Contacthero from "../../components/Contacthero/Contacthero";
// import ContactCard from "../../components/ContactCard/ContactCard";
import ContactCard2 from "../../components/ContactCard2/ContactCard2";

const Contactpage = () => {
  return (
    <div>
      <Contacthero />
      <ContactCard2 />
    </div>
  );
};

export default Contactpage;
