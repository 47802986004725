import React from 'react';
import ServiceHero from '../../components/ServiceHero/ServiceHero';
import Services from "../../components/Services/Services";

const Service = () => {
  return (
    <div>
        <ServiceHero />
        <Services />
    </div>
  )
}

export default Service