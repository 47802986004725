import React, { useEffect } from "react";
import "./SingleService.css";

import ServicesData from "../../data/ServicesData.jsx";
import { useParams } from "react-router-dom";

const SingleService = () => {
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="linksoft_single-service_main">
      <p className="linksoft_single-service_title">
        {ServicesData.filter((item) => item.id === parseInt(id))[0].title}
      </p>
      {ServicesData.filter((item) => item.id === parseInt(id))[0].content}
    </div>
  );
};

export default SingleService;
