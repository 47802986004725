import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./common/Navbar/Navbar";
import Footer from "./common/Footer/Footer";
import Landing from "./pages/Landing Page/Landing";
import Services from "./pages/Services/Service";
import Jobs from "./pages/Jobs/Jobspage";
import About from "./pages/About/Aboutpage";
import Contact from "./pages/Contact/Contact";
import Jobdetails from "./pages/JobDetails/Jobdetails";
import SingleService from "./components/SingleService/SingleService";

const App = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/service" element={<Services />} />
        <Route path="/service/:id/:title" element={<SingleService />} />
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/job-details" element={<Jobdetails />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
