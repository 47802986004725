import React from "react";
import "./Testimonials.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { BiSolidQuoteLeft, BiSolidStar } from "react-icons/bi";

import client1 from "../../assets/clients/client1.svg";
import client2 from "../../assets/clients/client2.svg";
import client3 from "../../assets/clients/client3.svg";

const Testimonials = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <div className="recruitment_testimonials">
      <p className="recruitment_testimonial_heading">Clients Feedback </p>
      <p className="recruitment_testimonial_desc">
        At LinkSoft Consulting, we pride ourselves on delivering exceptional recruitment services and building strong relationships with both our clients and candidates. We believe that the success of our agency lies in the satisfaction and positive experiences of those we work with. Here's what some of our clients have to say about their experience with us:
      </p>
      <Carousel
        showDots={true}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlaySpeed={1000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {/* //Card 1 */}
        <div className="recruitment_testimonial_card">
          <div className="recruitment_testimonial_client-details_main">
            <div className="recruitment_testimonial_client-details">
              <img src={client1} alt="" />
              <div className="recruitment_testimonial_client_name_div">
                <p>John Smith</p>
                <p className="recruitment_testimonial_client_post">Developer</p>
              </div>
            </div>
            <BiSolidQuoteLeft className="recruitment_testimonial_quote" />
          </div>
          <p className="recruitment_testimonial_client_desc">
            "I have been working with LinkSoft Consulting for the past two years, and I can confidently say that they are the best recruitment agency I have ever collaborated with. They truly understand our business needs and go above and beyond to find top-notch candidates who are the perfect fit for our company. Their professionalism, attention to detail, and commitment to excellence are unparalleled. I highly recommend LinkSoft Consulting for all your recruitment needs."
          </p>
          <div className="recruitment_testimonial_client_rating-div">
            <p className="recruitment_testimonial_client_rating">Ratings</p>
            <p className="recruitment_testimonial_client_stars">
              <BiSolidStar />
              <BiSolidStar />
              <BiSolidStar />
              <BiSolidStar />
            </p>
          </div>
        </div>
        {/* //Card 2 */}
        <div className="recruitment_testimonial_card">
          <div className="recruitment_testimonial_client-details_main">
            <div className="recruitment_testimonial_client-details">
              <img src={client2} alt="" />
              <div className="recruitment_testimonial_client_name_div">
                <p>Sarah Johnson</p>
                <p className="recruitment_testimonial_client_post">Developer</p>
              </div>
            </div>
            <BiSolidQuoteLeft className="recruitment_testimonial_quote" />
          </div>
          <p className="recruitment_testimonial_client_desc">
            "We have been partnering with LinkSoft Consulting for several years now, and they have consistently exceeded our expectations. Their team is incredibly responsive and proactive in sourcing highly qualified candidates for our open positions. They take the time to understand our company culture, which has resulted in successful hires that have seamlessly integrated into our teams. Working with LinkSoft Consulting has made our recruitment process much more efficient and effective."
          </p>
          <div className="recruitment_testimonial_client_rating-div">
            <p className="recruitment_testimonial_client_rating">Ratings</p>
            <p className="recruitment_testimonial_client_stars">
              <BiSolidStar />
              <BiSolidStar />
              <BiSolidStar />
              <BiSolidStar />
            </p>
          </div>
        </div>
        {/* //Card 3 */}
        <div className="recruitment_testimonial_card">
          <div className="recruitment_testimonial_client-details_main">
            <div className="recruitment_testimonial_client-details">
              <img src={client3} alt="" />
              <div className="recruitment_testimonial_client_name_div">
                <p>Kunal Verma</p>
                <p className="recruitment_testimonial_client_post">Developer</p>
              </div>
            </div>
            <BiSolidQuoteLeft className="recruitment_testimonial_quote" />
          </div>
          <p className="recruitment_testimonial_client_desc">
           "Finding the right talent is critical for the growth and success of our organization. LinkSoft Consulting has been instrumental in helping us achieve this goal. Their extensive network and thorough screening process have allowed us to connect with exceptional candidates who possess the skills and experience we require. LinkSoft Consulting's dedication, professionalism, and personalized approach have made them an invaluable partner in our recruitment efforts."
          </p>
          <div className="recruitment_testimonial_client_rating-div">
            <p className="recruitment_testimonial_client_rating">Ratings</p>
            <p className="recruitment_testimonial_client_stars">
              <BiSolidStar />
              <BiSolidStar />
              <BiSolidStar />
              <BiSolidStar />
            </p>
          </div>
        </div>
        
        {/* //Card 4 */}
        <div className="recruitment_testimonial_card">
          <div className="recruitment_testimonial_client-details_main">
            <div className="recruitment_testimonial_client-details">
              <img src={client2} alt="" />
              <div className="recruitment_testimonial_client_name_div">
                <p>Claire Stinson</p>
                <p className="recruitment_testimonial_client_post">Developer</p>
              </div>
            </div>
            <BiSolidQuoteLeft className="recruitment_testimonial_quote" />
          </div>
          <p className="recruitment_testimonial_client_desc">
            "Working with LinkSoft Consulting has been an absolute pleasure. Their team is incredibly knowledgeable and takes the time to understand our unique hiring needs. They have consistently provided us with top-tier candidates who not only possess the required skills but also align with our company values. LinkSoft Consulting has saved us significant time and effort in our recruitment process, and we highly recommend their services to any organization seeking exceptional talent."
          </p>
          <div className="recruitment_testimonial_client_rating-div">
            <p className="recruitment_testimonial_client_rating">Ratings</p>
            <p className="recruitment_testimonial_client_stars">
              <BiSolidStar />
              <BiSolidStar />
              <BiSolidStar />
              <BiSolidStar />
            </p>
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default Testimonials;
