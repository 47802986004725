import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import "./navbar.css";
import { AiOutlineMenu } from "react-icons/ai";
import { BsXLg } from "react-icons/bs";
import Logo from "../../assets/Asset 5.png"

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="recruitment_navbar">
      <div className="recruitment_navbar-menu">
        {toggleMenu ? (
          <BsXLg
            className="recruitment_navbar-menu_toggleBtn"
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <AiOutlineMenu
            className="recruitment_navbar-menu_toggleBtn"
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="recruitment_navbar-menu_container">
            <div className="recruitment_navbar-menu_container-links">
              <li onClick={() => setToggleMenu(false)}>
                <NavLink to="/">Home</NavLink>
              </li>
              <li onClick={() => setToggleMenu(false)}>
                <NavLink to="/service">Service</NavLink>
              </li>
              <li onClick={() => setToggleMenu(false)}>
                <NavLink to="/jobs">Job</NavLink>
              </li>
              <li onClick={() => setToggleMenu(false)}>
                <NavLink to="/about-us">About Us</NavLink>
              </li>
              <li onClick={() => setToggleMenu(false)}>
                <NavLink to="/contact-us">Contact Us</NavLink>
              </li>
              <li onClick={() => setToggleMenu(false)}>
                <NavLink to="/login">Log in</NavLink>
              </li>
              <li onClick={() => setToggleMenu(false)}>
                <NavLink to="/signin">Sign in</NavLink>
              </li>
            </div>
          </div>
        )}
      </div>
      <div className="recruitment_navbar-links">
        <div className="recruitment_navbar-links_logo_container">
          <Link to="/" className="recruitment_navbar_logo">
            <img src={Logo} alt="" />
          </Link>
        </div>
        <div className="recruitment_navbar-links_container">
          <li className="recruitment_navbar-links_li">
            <NavLink to="/">Home</NavLink>
          </li>
          <li className="recruitment_navbar-links_li">
            <NavLink to="/service">Service</NavLink>
          </li>
          <li className="recruitment_navbar-links_li">
            <NavLink to="/jobs">Job</NavLink>
          </li>
          <li className="recruitment_navbar-links_li">
            <NavLink to="/about-us">About Us</NavLink>
          </li>
          <li className="recruitment_navbar-links_li">
            <NavLink to="/contact-us">Contact Us</NavLink>
          </li>
        </div>
        <div className="recruitment_navbar-links_login-sign_div">
          <li className="recruitment_navbar-links_li">
            <NavLink to="/login">Log in</NavLink>
          </li>
          <li className="recruitment_navbar-links_li">
            <NavLink to="/signin">Sign in</NavLink>
          </li>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
