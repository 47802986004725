import React from 'react'
import Abouthero from '../../components/AboutHero/AboutHero'
import Counter from '../../components/Counter/Counter'
import Contact from '../../components/Contact/Contact'
import About from "../../components/About/About";

const Aboutpage = () => {
  return (
    <div>
        <Abouthero />
        <About />
        <Counter />
        <Contact />
    </div>
  )
}

export default Aboutpage