import React from "react";
import "./About.css";

import team from "../../assets/landingPage/team.svg";

import { HiOutlineArrowNarrowRight } from "react-icons/hi";

const About = () => {
  return (
    <div className="recruitment_about">
      <div className="recruitment_about_content">
        <p className="recruitment_about_title">About Us</p>
        <p className="recruitment_about_desc">
          We believe that everyone deserves the opportunity to live their best
          life.
        </p>
        <p className="recruitment_about_desc">
          We are committed to helping people achieve their dreams, and we're
          excited to offer our services to you.
        </p>
        <button className="recruitment_about_btn">
          Learn More{" "}
          <HiOutlineArrowNarrowRight className="recruitment_about_btn_arrow" />
        </button>
      </div>
      <img className="recruitment_about_img" src={team} alt="" />
    </div>
  );
};

export default About;
