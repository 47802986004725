import React from "react";
import "./Contact.css";
import lady from "../../assets/contact/lady.svg";

const Contact = () => {
  return (
    <div className="recruitment_contact">
      <div className="recruitment_contact_content">
        <p className="recruitment_contact_title">We are Here to Help You</p>
        <p className="recruitment_contact_desc">
          Our dedicated recruitment agency is committed to providing exceptional assistance. With our expertise, we aim to guide and support you throughout your job search journey. Trust us to help you find the perfect career opportunity.
        </p>
        <button className="recruitment_contact_btn">CONTACT US</button>
      </div>
      <img className="recruitment_contact_img" src={lady} alt="" />
    </div>
  );
};

export default Contact;
