import React from 'react'
import './contacthero.css'
import lady from "../../assets/contacthero/young-woman-working-office-with-laptop-headphones-white-wall-customer-service-call-center (1) 1.png";
import ladyBg from "../../assets/landingPage/blueCircle.svg";

const Contacthero = (props) => {
    return (
        <div className="recruitment_contacthero">
          <div className="recruitment_contacthero_content">
            <div className="recruitment_contacthero_content_heading-div">
              <p className="recruitment_contacthero_heading">Contact Us</p>
              
            </div>
            <p className="recruitment_contacthero_desc">
              Get in touch with us to discuss your career goals and find the perfect job match. Let's connect and make it happen!
            </p>
            <div className="recruitment_contacthero_search_main">
              <button>Explore Jobs</button>
            </div>
          </div>
          <div className="recruitment_contacthero_img-div">
            <img className="recruitment_contacthero_img" src={lady} alt="" />
            <img className="recruitment_contacthero_imgBg" src={ladyBg} alt="" />
          </div>
        </div>
      );
    };

export default Contacthero
