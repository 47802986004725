import React from "react";
import "./contactcard2.css";
import location from "../../assets/contactcard/Vector.png";
import whitecall from "../../assets/contactcard/bxs_phone-call.png";
import mail from "../../assets/contactcard/ic_sharp-email.png";
import insta from "../../assets/contactcard/insta.png";
import facebook from "../../assets/contactcard/facebook.png";
import twitter from "../../assets/contactcard/twitter.png";
import youtube from "../../assets/contactcard/youtube.png";

const ContactCard2 = () => {
  return (
    <div className="linksoft_contact2">
      <div className="linksoft_contact2-card-main">
        <div className="linksoft_contact2-card-section2">
          <h2 className="linksoft_contact2-card-contact-info">
            Contact Information
          </h2>
          <div className="linksoft_contact2-card_content">
            <div className="linksoft_contact2-card_email-phone">
              <img src={whitecall} alt="" />
              <p className="linksoft_contact2-card_details">+91 81422 21022</p>
            </div>
            <div className="linksoft_contact2-card_email-phone">
              <img src={mail} alt="" />
              <p className="linksoft_contact2-card_details">
                info@linksoftconsulting.com
              </p>
            </div>
            <div className="linksoft_contact2-card-location-input">
              <img src={location} alt="" />
              <div className="linksoft_contact2-card_location">
                <p className="linksoft_contact2-card_details">
                  No.27, 1A, 2nd Cross Rd, Vijaya Bank Colony, Dodda Banaswadi,
                  Bengaluru, Karnataka 560043
                </p>
                {/* <p className="linksoft_contact2-card_desc"></p> */}
              </div>
            </div>{" "}
          </div>
          <div className="linksoft_contact2-card-icons">
            <a
              href="https://www.instagram.com/linksoft_consulting/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={insta} alt="" />
            </a>
            <a
              href="http://facebook.com/linksoftconsulting"
              target="_blank"
              rel="noreferrer"
            >
              <img src={facebook} alt="" />
            </a>
            <a
              href="https://twitter.com/LinksoftConsult"
              target="_blank"
              rel="noreferrer"
            >
              <img src={twitter} alt="" />
            </a>
            <a
              href="http://youtube.com/linksoftconsulting"
              target="_blank"
              rel="noreferrer"
            >
              <img src={youtube} alt="" />
            </a>
          </div>
        </div>
        <div className="linksoft_contact2-card-section2">
          <h2 className="linksoft_contact2-card-contact-info">
            Contact Information
          </h2>
          <div className="linksoft_contact2-card_content">
            <div className="linksoft_contact2-card_email-phone">
              <img src={whitecall} alt="" />
              <p className="linksoft_contact2-card_details">+91 81422 21022</p>
            </div>
            <div className="linksoft_contact2-card_email-phone">
              <img src={mail} alt="" />
              <p className="linksoft_contact2-card_details">
                info@linksoftconsulting.com
              </p>
            </div>
            <div className="linksoft_contact2-card-location-input">
              <img src={location} alt="" />
              <div className="linksoft_contact2-card_location">
                <p className="linksoft_contact2-card_details">
                  Guntur, Andhra Pradesh
                </p>
                {/* <p className="linksoft_contact2-card_desc"></p> */}
              </div>
            </div>
          </div>
          <div className="linksoft_contact2-card-icons">
            <a
              href="https://www.instagram.com/linksoft_consulting/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={insta} alt="" />
            </a>
            <a
              href="http://facebook.com/linksoftconsulting"
              target="_blank"
              rel="noreferrer"
            >
              <img src={facebook} alt="" />
            </a>
            <a
              href="https://twitter.com/LinksoftConsult"
              target="_blank"
              rel="noreferrer"
            >
              <img src={twitter} alt="" />
            </a>

            <a
              href="http://youtube.com/linksoftconsulting"
              target="_blank"
              rel="noreferrer"
            >
              <img src={youtube} alt="" />
            </a>
          </div>
        </div>
      </div>
      <div className="linksoft_contact2-card-section1">
        <p className="linksoft_contact2-card-section1_title">Get in touch</p>
        <div className="linksoft_contact2-card-section1-content">
          <div className="linksoft_contact2-card-inputs">
            <div className="linksoft_contact2-card-name">
              <h6>First Name</h6>
              <input className="linksoft_contact2-card-input" type="text" />
            </div>
            <div className="linksoft_contact2-card-lname">
              <h6>Last Name</h6>
              <input className="linksoft_contact2-card-input" type="text" />
            </div>
          </div>
          <div className="linksoft_contact2-card-inputs">
            <div className="linksoft_contact2-card-name">
              <h6>Email</h6>
              <input className="linksoft_contact2-card-input" type="text" />
            </div>
            <div className="linksoft_contact2-card-lname">
              <h6>Phone</h6>
              <input className="linksoft_contact2-card-input" type="text" />
            </div>
          </div>

          <div className="linksoft_contact2-card-inputs">
            <div className="linksoft_contact2-card-message">
              <h6>Message</h6>
              <textarea
                rows="1"
                className="linksoft_contact2-card-input"
                placeholder="Write your message"
              />
            </div>
          </div>
          <button className="linksoft_contact2-card-button">
            Send Message
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContactCard2;
